import React, { useEffect } from 'react'
import Meta from './Core/Meta'
import slugify from 'slugify'
import { graphql, useStaticQuery } from 'gatsby'

export const head =
  (key: string, type?: string) =>
  ({ location, data }: { location: any; data: any }) => {
    const gatsbyData = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `)

    let siteUrl = gatsbyData.site.siteMetadata.siteUrl
    let pagesIntl = data.allPageIntl.edges
    let page = data[key]
    if (type == 'residence-multiple') {
      page = data[key].nodes[0]
    }
    let pageTitle = page?.metaTitle
      ? page?.metaTitle
      : page?.title
      ? page?.title.replace(/\n/g, '')
      : 'Home'

    let metaDescription = page?.metaDescription || 'description'
    let twitterCard = page?.twitterCard
      ? page?.twitterCard
      : page?.imageCover
      ? page?.imageCover?.childImageSharp?.gatsbyImageData?.images?.fallback
      : ''
    let facebookCard = page?.facebookCard
      ? page?.facebookCard
      : page?.imageCover
      ? page?.imageCover?.childImageSharp?.gatsbyImageData?.images?.fallback
      : '' || ''
    let robotFollow = page?.metaFollow || 'Yes'
    let robotIndex = page?.metaIndex || 'Yes'
    let _url = page?._url
    let pageName = page?.name

    if (type === 'faq') {
      page =
        data.allUmbracoFaqPage.nodes && data.allUmbracoFaqPage.nodes.length > 0
          ? data.allUmbracoFaqPage.nodes[0]
          : {}
    }
    let city = null
    let cityUrlName = null
    if (type === 'residence-by-city') {
      const cities = data.allUmbracoCity
      city = cities.nodes.find((city: any) => {
        const citySlug = slugify(city.name, { lower: true })
        const locationPathname = location.pathname
          .replace(page?._url, '')
          .replace('/', '')

        return citySlug === locationPathname
      })
      let locationPathname = location?.pathname
      if (locationPathname.endsWith('/')) {
        locationPathname = location.pathname.slice(0, -1)
      }
      cityUrlName =
        locationPathname.split('/')[locationPathname.split('/').length - 1]
      if (city) {
        pageTitle =
          city && city.metaTitle
            ? city.metaTitle
            : page?.metaTitle
            ? page?.metaTitle
            : page?.title
            ? city
            : `${'Listing residence' + ' - ' + city.name}`

        if (city.metaDescription) metaDescription = city.metaDescription
        if (city.twitterCard) twitterCard = city.twitterCard
        if (city.facebookCard) facebookCard = city.facebookCard
        if (city.metaFollow) robotFollow = city.metaFollow
        if (city.metaIndex) robotIndex = city.metaIndex
        if (city._url) _url = city._url
        if (city.name) pageName = city.name
      }
    }

    // const {
    //   allPageIntl: { edges: pagesIntl },
    // } = useStaticQuery(graphql`
    //   query MetaQuery {
    //     allPageIntl {
    //       edges {
    //         node {
    //           name
    //           uri
    //           alternates {
    //             name
    //             uri
    //             lang
    //           }
    //         }
    //       }
    //     }
    //   }
    // `)

    let foundPageIntl = null

    for (const item of pagesIntl) {
      if (item.node && item.node.alternates && item.node.alternates.length) {
        const checkAlternate = item.node.alternates.find(
          (page: any) => page.name === pageName
        )
        if (checkAlternate) {
          foundPageIntl = item
          break
        }
      }
    }

    const hrefLangDefault =
      foundPageIntl &&
      foundPageIntl.node &&
      foundPageIntl.node.alternates &&
      foundPageIntl.node.alternates.find((item: any) => item.lang === 'fr')
    let hrefLangEn =
      foundPageIntl &&
      foundPageIntl.node &&
      foundPageIntl.node.alternates &&
      foundPageIntl.node.alternates.find((item: any) => item.lang === 'en')
    // if (
    //   hrefLangEn?.uri &&
    //   hrefLangEn?.uri[hrefLangEn?.uri?.length - 1] !== '/'
    // ) {
    //   hrefLangEn.uri = hrefLangEn.uri + '/'
    // }

    useEffect(() => {
      const timeout = setTimeout(() => {
        document.title = pageTitle
      }, 100)
      return () => {
        clearTimeout(timeout)
      }
    }, [])
    let urlFr = `${siteUrl}${
      hrefLangDefault && hrefLangDefault.uri !== '/' ? hrefLangDefault.uri : ''
    }`
    let urlEn = hrefLangEn ? siteUrl + '' + hrefLangEn.uri : ''

    return (
      <>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription}></meta>
        <meta
          name="robots"
          content={`${
            robotFollow === 'No' ? 'nofollow' : 'follow'
          },${'noindex'}`}
          // content={`${robotFollow === 'No' ? 'nofollow' : 'follow'},${
          //   robotIndex === 'No' ? 'noindex' : 'index'
          // }`}
        ></meta>

        <meta name="og:type" property="og:type" content="website"></meta>
        <meta property="og:title" content={pageTitle}></meta>
        {metaDescription && (
          <meta property="og:description" content={metaDescription}></meta>
        )}
        {location.href && (
          <meta property="og:url" content={location.href}></meta>
        )}
        {facebookCard?.src && (
          <meta property="og:image" content={facebookCard?.src}></meta>
        )}

        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twiter:title" content={pageTitle}></meta>
        {metaDescription && (
          <meta property="twiter:description" content={metaDescription}></meta>
        )}
        {location.href && (
          <meta property="twiter:url" content={location.href}></meta>
        )}
        {twitterCard?.src && (
          <meta property="twitter:image" content={twitterCard?.src}></meta>
        )}

        {location?.pathname && (
          <link
            rel="canonical"
            href={`${siteUrl}${
              location?.pathname !== '/' ? location?.pathname : ''
            }`}
          ></link>
        )}

        {hrefLangDefault && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={
              location && location.pathname.includes('/en/')
                ? `${urlEn}${
                    cityUrlName && !urlEn.includes(cityUrlName)
                      ? cityUrlName
                      : ''
                  }`
                : `${urlFr}${
                    cityUrlName && !urlFr.includes(cityUrlName)
                      ? cityUrlName
                      : ''
                  }`
            }
          />
        )}

        {hrefLangDefault && (
          <link
            rel="alternate"
            hrefLang="fr"
            href={`${urlFr}${
              cityUrlName && !urlFr.includes(cityUrlName) ? cityUrlName : ''
            }`}
          />
        )}

        {hrefLangEn && (
          <link
            rel="alternate"
            hrefLang="en"
            href={`${urlEn}${
              cityUrlName && !urlEn.includes(cityUrlName) ? cityUrlName : ''
            }`}
            // href={hrefLangEn ? `${siteUrl}${hrefLangEn.uri}` : '' }
          />
        )}
        {/* <Meta
          data={{
            metaTitle: pageTitle,
            metaDescription: metaDescription,
            twitterCard: twitterCard,
            facebookCard: facebookCard,
            robotFollow: robotFollow,
            robotIndex: robotIndex,
            _url: _url,
            pageName: pageName,
          }}
        /> */}
      </>
    )
  }
